<template>
    <Dropdown trigger="click" @on-click="handleChange">
        <a href="javascript:void(0)">
            {{ text }}
            <Icon type="ios-arrow-down"></Icon>
        </a>
        <template #list>
            <DropdownMenu>
                <DropdownItem v-for="item in langList" :key="item.label" :name="item.label">{{ item.value }}</DropdownItem>
            </DropdownMenu>
        </template>
    </Dropdown> 
</template>
<script>
    import util from '@/libs/util'

    export default {
        name: "",
        mixins: [],
        components: {},
        props: [],
        data () {
            return {
                langList: [
                    {
                        value: '简体中文',
                        label: 'zh-Hans'
                    },
                    {
                        value: 'English',
                        label: 'en'
                    }
                ],
                lang: 'zh-Hans',
            }
        },
        computed: {
            text() {
                let result = this.langList.find((item) => this.lang === item.label)
                return result.value
            }
        }, 
        methods: {
            changeLocale(val) {
                this.$i18n.locale = val
            },
            handleChange(e) {
                this.lang = e
                this.changeLocale(e)
                util.changeTitle('layout.sysName', e)
                localStorage.setItem('lang', e)
                // this.$store.dispatch('GET_AREAS_LIST')
                this.$store.commit('SET_LANG', e)
            }
        },
        mounted() {
            this.lang = localStorage.getItem('lang') || 'zh-Hans'
            this.changeLocale(this.lang)
            this.$store.commit('SET_LANG', this.lang)
            util.changeTitle('layout.sysName', this.lang)
        }
    }
</script>
<style scoped>
    
</style>